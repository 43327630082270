.hero{
    width: 100vw;
    background-image: linear-gradient(to bottom, transparent, transparent, transparent, #fff), url(../../assets/bgIMG.png);
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.home{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5rem;
    gap: 1rem;
}

.home >img{
    width: 6rem;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.home small{
    font-size: 2rem;
}

.home small>span{
    color: #6a5acd;
}
.home >p{
    width: 40%;
    font-size: 1rem;
    text-align: center;
}


.home__image{
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 4rem;
    
}

.home__image img{
    width: 50%;
    filter: brightness(90%);
    -webkit-filter: brightness(90%);
}
.btn{
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: center;
}

.btn img{
    width: 1rem;
    margin-right: 0.5rem;
    filter: invert();
    -webkit-filter: invert();
}

.btn > button{
    display: flex;
    align-items: center;
    color: #fff;
    background: rgb(167, 154, 253);
    font-weight: 600;
    padding: .3rem .7rem;
    box-shadow: 0 2px 6px rgba(0,0,0,0.2);
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.btn a{
    font-size: 12px;
    color: #000000;
    font-weight: 500;
    background: #fff;
    padding: .3rem .7rem;
    border-radius: 5px;
    box-shadow: 0 2px 6px rgba(0,0,0,0.2);
    display: flex;
    justify-content: center;
}
.btn a img{
    filter: none;
    -webkit-filter: none;
}

.btn a:hover{
    color: #8a2ce2;
}
.social__links{
    position: fixed;
    left: 3vw;
    bottom: 3vh;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: fit-content;
    z-index: 3;
}

.social__links img{
    opacity: .6;
    width: 1.5rem;
}

.social__links img:hover{
    opacity: 1;
}

.scroll__buttons{
    position: fixed;
    right: 1rem;
    top: 40%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.scroll__buttons a button{
    background: #808080d1;
    width: .8rem;
    height: .8rem;
    cursor: pointer;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    transition: transform 2s ease;
    -webkit-transition: transform 2s ease;
    -moz-transition: transform 2s ease;
    -ms-transition: transform 2s ease;
    -o-transition: transform 2s ease;
}

.scroll__buttons a button:hover{
    scale: 1.2;
}
.scroll__buttons a .active{
    background: #000000;
}


@media screen and (max-width: 768px){
    .home{
        justify-content: center;
        align-items: center;
        margin-left: 1rem;
    }
    .home small{
        font-size: 1.3rem;
    }

    .home p{
        font-size: 12px;
        width: 80%;
    }
    .home__image img{
        margin: 0;
        width: 90%;
    }

    .btn button{
        background: #4839a9;
    }
    .btn button, a{
        font-size: 12px;
        font-weight: 300;
    }
    .scroll__buttons{
        right: 1.5rem;
    }
    .scroll__buttons a button{
        width: .6rem;
        height: .6rem;
    
    }

}

