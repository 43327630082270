.skills__items {
    width: 50vw;
    margin: 4rem auto;
    display: flex;
    justify-content: center;
    gap: 4rem;
    flex-wrap: wrap;
  }
  
  .skill__left {
    width: fit-content;
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    margin: auto;
    gap: 2rem;
    flex-wrap: wrap;
    padding: 2rem;
  }
  
  .skill__details {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    transition: transform 0.3s ease; /* Add smooth transition to transform */
  }
  
  .skill__details img {
    width: 5.5rem;
    padding: .5rem;
    border-radius: 50%;
    background: rgb(228, 228, 228);
    transition: transform 0.3s ease; /* Add smooth transition to transform */
  }
  
  .skill__details span {
    font-weight: bold;
    color: #787777;
  }
  
  .skill__right {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding: 2rem;
  }
  
  .skill__right .achievement {
    display: flex;
    justify-content: flex-start;
    gap: 2rem;
    transition: transform 0.3s ease; /* Add smooth transition to transform */
  }
  
  .skill__right .achievement .year {
    font-size: 1.1rem;
    color: #787777;
  }
  
  .school span {
    font-weight: bold;
    color: #787777;
    margin-bottom: .4rem;
  }
  
  .school > :nth-child(2) {
    color: #000;
    font-size: 16px;
  }
  
  .skill__right .achievement .school {
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
  }
  
  
  .school__about p {
    width: 25rem;
    font-size: 14px;
    color: #474646;
    background: #fff;
    border-radius: .5rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    padding: 1rem;
    margin: 1rem auto;
    display: none;
    transition: transform 0.3s ease; /* Add smooth transition to transform */
  }
  
  .school:hover .school__about p {
    display: flex;
    transform: translateY(-10%);
    transition: transform 0.4s ease; /* Adjust the duration for a smoother transition */
  }
  