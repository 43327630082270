
  
  .cursor {
    position: fixed;
    width: 20px;
    height: 20px;
    background: radial-gradient(circle, #ff5733, #f7dc6f);
    border-radius: 50%;
    pointer-events: none;
    mix-blend-mode: none;
    transition: transform 5s ease;
    z-index: 9999;
    -webkit-transition: transform 5s ease;
    -moz-transition: transform 5s ease;
    -ms-transition: transform 5s ease;
    -o-transition: transform 5s ease;
}
  
  .cursor.fire {
    width: 40px;
    height: 40px;
    animation: fire 4s infinite;
    -webkit-animation: fire 4s infinite;
}
  
  @keyframes fire {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
  